import { Headphones, Users, Clock, MessageCircle, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';

export function CommunitySupport() {
  const features = [
    {
      icon: <Clock className="w-6 h-6" />,
      title: "24/7 Support",
      description: "Round-the-clock moderation and user assistance."
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Community Events",
      description: "Regular events and activities to boost engagement."
    },
    {
      icon: <MessageCircle className="w-6 h-6" />,
      title: "User Support",
      description: "Dedicated team to handle inquiries and resolve issues."
    }
  ];

  return (
    <div className="pt-16">
      <section className="py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center mb-8">
            <Headphones className="w-12 h-12 mr-4" />
            <h2 className="text-4xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              Community Support
            </h2>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-400">
              Comprehensive community support services to keep your Discord server active, engaging, and well-moderated.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8 mb-16">
            {features.map((feature, index) => (
              <div key={index} className="p-6 rounded-lg bg-white/5 border border-white/10">
                <div className="flex items-center mb-4">
                  {feature.icon}
                  <h3 className="text-xl font-semibold ml-3">{feature.title}</h3>
                </div>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>

          <div className="bg-white/5 border border-white/10 rounded-lg p-8 mb-12">
            <h3 className="text-2xl font-bold mb-6">Support Services Include</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="w-6 h-6 rounded-full bg-white/10 flex items-center justify-center mr-4 mt-1">✓</span>
                <span>Active moderation and conflict resolution</span>
              </li>
              <li className="flex items-start">
                <span className="w-6 h-6 rounded-full bg-white/10 flex items-center justify-center mr-4 mt-1">✓</span>
                <span>Community event planning and execution</span>
              </li>
              <li className="flex items-start">
                <span className="w-6 h-6 rounded-full bg-white/10 flex items-center justify-center mr-4 mt-1">✓</span>
                <span>User engagement initiatives</span>
              </li>
              <li className="flex items-start">
                <span className="w-6 h-6 rounded-full bg-white/10 flex items-center justify-center mr-4 mt-1">✓</span>
                <span>Regular community feedback collection</span>
              </li>
            </ul>
          </div>

          <div className="text-center">
            <Link
              to="/login"
              className="inline-flex items-center px-8 py-3 rounded-full bg-white text-black hover:bg-gray-200 transition-colors text-lg font-medium"
            >
              <Zap className="w-5 h-5 mr-2" />
              Get Started with Community Support
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}