import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Settings2, PlusCircle, MessageSquare, BarChart3,
  Clock, DollarSign, Star, Users, CheckCircle2, Briefcase
} from 'lucide-react';
import { useAuthStore } from '../../store/useAuthStore';

export function DashboardPage() {
  const { user, isFreelancerMode, setFreelancerMode } = useAuthStore();
  const navigate = useNavigate();

  const [commissions, setCommissions] = React.useState<any[]>([]);
  const [messages, setMessages] = React.useState<any[]>([]);
  const [stats, setStats] = React.useState({
    totalEarnings: 1250,
    completedProjects: 8,
    avgRating: 4.6,
    activeProjects: 2,
  });

  React.useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    // Mock placeholder data
    setCommissions([
      {
        id: '1',
        title: 'Website Fixes',
        description: 'Fix bugs and update styling on a landing page.',
        status: 'in_progress',
        budget: 300,
        createdAt: new Date().toISOString(),
      },
      {
        id: '2',
        title: 'Bot Development',
        description: 'Create a custom Discord bot for moderation.',
        status: 'open',
        budget: 500,
        createdAt: new Date().toISOString(),
      },
    ]);

    setMessages([
      {
        id: 'msg1',
        sender: 'ClientOne',
        timestamp: 'Today, 2:15 PM',
        content: 'Can you give an update on the project status?',
      },
      {
        id: 'msg2',
        sender: 'ClientTwo',
        timestamp: 'Yesterday, 10:00 AM',
        content: 'Thanks for completing the job!',
      },
    ]);
  }, [user, isFreelancerMode]);

  if (!user) return null;

  return (
    <div className="pt-16">
      <div className="min-h-screen py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center mb-12">
            <h1 className="text-4xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              {isFreelancerMode ? 'Freelancer Dashboard' : 'Client Dashboard'}
            </h1>

            {user.isFreelancer && (
              <div className="flex items-center space-x-4">
                <span className="text-gray-400">Mode:</span>
                <button
                  onClick={() => setFreelancerMode(!isFreelancerMode)}
                  className="flex items-center px-4 py-2 rounded-full bg-white/5 border border-white/10 hover:border-white/20"
                >
                  <Settings2 className="w-4 h-4 mr-2" />
                  {isFreelancerMode ? 'Freelancer' : 'Client'}
                </button>
              </div>
            )}
          </div>

          {isFreelancerMode && (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-12">
              <StatCard icon={<DollarSign className="w-5 h-5 mr-2 text-green-400" />} title="Total Earnings" value={`$${stats.totalEarnings}`} />
              <StatCard icon={<CheckCircle2 className="w-5 h-5 mr-2 text-blue-400" />} title="Completed" value={stats.completedProjects} />
              <StatCard icon={<Star className="w-5 h-5 mr-2 text-yellow-400" />} title="Avg Rating" value={`${stats.avgRating.toFixed(1)}/5.0`} />
              <StatCard icon={<Clock className="w-5 h-5 mr-2 text-purple-400" />} title="Active Projects" value={stats.activeProjects} />
            </div>
          )}

          <div className="flex space-x-4 mb-8">
            {!isFreelancerMode && (
              <button
                onClick={() => navigate('/commission/new')}
                className="inline-flex items-center px-6 py-3 rounded-full bg-white text-black hover:bg-gray-200 transition-colors"
              >
                <PlusCircle className="w-5 h-5 mr-2" />
                Create New Commission
              </button>
            )}
            <button
              onClick={() => navigate('/messages')}
              className="inline-flex items-center px-6 py-3 rounded-full bg-white/5 border border-white/10 hover:border-white/20 transition-colors"
            >
              <MessageSquare className="w-5 h-5 mr-2" />
              Messages
            </button>
            {isFreelancerMode && (
              <button
                onClick={() => navigate('/analytics')}
                className="inline-flex items-center px-6 py-3 rounded-full bg-white/5 border border-white/10 hover:border-white/20 transition-colors"
              >
                <BarChart3 className="w-5 h-5 mr-2" />
                Detailed Analytics
              </button>
            )}
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <div className="md:col-span-2 space-y-6">
              <h2 className="text-2xl font-semibold mb-4">
                {isFreelancerMode ? 'Available Commissions' : 'Your Commissions'}
              </h2>
              {commissions.map((commission) => (
                <div
                  key={commission.id}
                  className="p-6 rounded-lg bg-white/5 border border-white/10 hover:border-white/20 transition-all"
                >
                  <div className="flex justify-between items-start mb-4">
                    <h3 className="text-xl font-bold">{commission.title}</h3>
                    <span className={`px-3 py-1 rounded-full text-sm ${
                      commission.status === 'open' ? 'bg-green-500/20 text-green-400' :
                      commission.status === 'in_progress' ? 'bg-yellow-500/20 text-yellow-400' :
                      'bg-blue-500/20 text-blue-400'
                    }`}>
                      {commission.status}
                    </span>
                  </div>
                  <p className="text-gray-400 mb-4">{commission.description}</p>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-400">Budget: ${commission.budget}</span>
                    <div className="flex items-center space-x-2 text-sm text-gray-400">
                      <Clock className="w-4 h-4" />
                      <span>{new Date(commission.createdAt).toLocaleDateString()}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="space-y-6">
              <h2 className="text-2xl font-semibold mb-4">Recent Messages</h2>
              {messages.map((message) => (
                <div
                  key={message.id}
                  className="p-4 rounded-lg bg-white/5 border border-white/10 hover:border-white/20 transition-all"
                >
                  <div className="flex items-center mb-2">
                    <div className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center mr-3">
                      <Users className="w-4 h-4" />
                    </div>
                    <div>
                      <p className="font-semibold">{message.sender}</p>
                      <p className="text-sm text-gray-400">{message.timestamp}</p>
                    </div>
                  </div>
                  <p className="text-gray-300">{message.content}</p>
                </div>
              ))}

              {isFreelancerMode && (
                <>
                  <h2 className="text-2xl font-semibold mb-4 mt-8">Recent Activity</h2>
                  <div className="space-y-4">
                    {[1, 2, 3].map((_, i) => (
                      <div key={i} className="flex items-center space-x-3 text-sm text-gray-400">
                        <Briefcase className="w-4 h-4" />
                        <span>New commission completed</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const StatCard = ({ icon, title, value }: { icon: React.ReactNode, title: string, value: any }) => (
  <div className="p-6 rounded-lg bg-white/5 border border-white/10">
    <div className="flex items-center mb-2">
      {icon}
      <h3 className="text-lg font-semibold">{title}</h3>
    </div>
    <p className="text-2xl font-bold">{value}</p>
  </div>
);
