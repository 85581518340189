export function About() {
  return (
    <div className="pt-16">
      <section className="py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            About ServicesX
          </h2>
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <p className="text-gray-400 text-lg mb-6">
                ServicesX connects clients with expert freelancers to bring ideas to life. Whether you need custom development, design, or full-scale management, we deliver top-tier solutions tailored to your needs.
              </p>
              <p className="text-gray-400 text-lg mb-6">
                Our team is dedicated to efficiency, quality, and innovation,ensuring every project is handled with expertise and professionalism.
              </p>
              <p className="text-gray-400 text-lg">
                We believe in empowering businesses and communities with seamless, high-quality services that drive success and growth.
              </p>
            </div>
            {/* Avatar Section */}
            <div className="flex justify-center space-x-8 mt-10 items-center flex-wrap sm:flex-nowrap">
              {/* Avatar 1 */}
              <div className="relative group">
                <img 
                  src="./jake.jpg"
                  alt="Avatar 1" 
                  className="w-32 h-32 rounded-full border-4 border-gray-500 shadow-lg transform transition-transform hover:scale-105 object-cover max-w-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex flex-col justify-center items-center text-white transition-opacity">
                  <span className="text-xl font-semibold">Jake</span>
                  <span className="text-sm text-gray-300 mt-1">CEO</span>
                </div>
              </div>

              {/* Avatar 2 */}
              <div className="relative group">
                <img 
                  src="./matei.jpg"
                  alt="Avatar 2" 
                  className="w-32 h-32 rounded-full border-4 border-gray-500 shadow-lg transform transition-transform hover:scale-105 object-cover max-w-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex flex-col justify-center items-center text-white transition-opacity">
                  <span className="text-xl font-semibold">Matei</span>
                  <span className="text-sm text-gray-300 mt-1">COO</span>
                </div>
              </div>

              {/* Avatar 3 */}
              <div className="relative group">
                <img 
                  src="./andrew.jpg"
                  alt="Avatar 3" 
                  className="w-32 h-32 rounded-full border-4 border-gray-500 shadow-lg transform transition-transform hover:scale-105 object-cover max-w-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex flex-col justify-center items-center text-white transition-opacity">
                  <span className="text-xl font-semibold">Andrew</span>
                  <span className="text-sm text-gray-300 mt-1">CTO</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h3 className="text-3xl font-bold mb-8 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Why Choose ServicesX?
          </h3>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="p-6 rounded-lg bg-white/5 border border-white/10">
              <h4 className="text-xl font-semibold mb-4">Expertise</h4>
              <p className="text-gray-400">Our team brings years of experience in custom development, design, and management solutions.</p>
            </div>
            <div className="p-6 rounded-lg bg-white/5 border border-white/10">
              <h4 className="text-xl font-semibold mb-4">Tailored Solutions</h4>
              <p className="text-gray-400">We create solutions that perfectly match your unique business or community needs.</p>
            </div>
            <div className="p-6 rounded-lg bg-white/5 border border-white/10">
              <h4 className="text-xl font-semibold mb-4">Reliable Support</h4>
              <p className="text-gray-400">Dedicated assistance ensures your projects run smoothly from start to finish.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}