import { create } from 'zustand';

type User = {
  id: string;
  username: string;
  isFreelancer: boolean;
};

type AuthStore = {
  user: User | null;
  isFreelancerMode: boolean;
  checkingSession: boolean;
  setUser: (user: User | null) => void;
  setFreelancerMode: (mode: boolean) => void;
  setCheckingSession: (value: boolean) => void;
  logout: () => void;
};

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  isFreelancerMode: localStorage.getItem('freelancerMode') === 'true',
  checkingSession: true,

  setUser: (user) =>
    set({
      user,
      isFreelancerMode: user?.isFreelancer || false,
    }),

  setFreelancerMode: (mode) => {
    localStorage.setItem('freelancerMode', mode.toString());
    set({ isFreelancerMode: mode });
  },

  setCheckingSession: (value) => set({ checkingSession: value }),

  logout: () => {
    localStorage.removeItem('freelancerMode');
    set({ user: null, isFreelancerMode: false, checkingSession: false });
  },
}));