import { Code2, Bot, Zap, Settings, Shield, BarChart } from 'lucide-react';
import { Link } from 'react-router-dom';

export function BotDevelopment() {
  const features = [
    {
      icon: <Bot className="w-6 h-6" />,
      title: "Custom Commands",
      description: "Tailor-made commands that fit your server's specific needs and workflow."
    },
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Moderation Tools",
      description: "Advanced moderation features to keep your community safe and organized."
    },
    {
      icon: <BarChart className="w-6 h-6" />,
      title: "Analytics",
      description: "Detailed insights into your server's activity and user engagement."
    },
    {
      icon: <Settings className="w-6 h-6" />,
      title: "Automation",
      description: "Automated tasks and workflows to streamline server management."
    }
  ];

  return (
    <div className="pt-16">
      <section className="py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center mb-8">
            <Code2 className="w-12 h-12 mr-4" />
            <h2 className="text-4xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              Bot Development
            </h2>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-400">
              Custom Discord bots that enhance your server's functionality and user experience. Our bots are built with scalability and reliability in mind.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-8 mb-16">
            {features.map((feature, index) => (
              <div key={index} className="p-6 rounded-lg bg-white/5 border border-white/10">
                <div className="flex items-center mb-4">
                  {feature.icon}
                  <h3 className="text-xl font-semibold ml-3">{feature.title}</h3>
                </div>
                <p className="text-gray-400">{feature.description}</p>
              </div>
            ))}
          </div>

          <div className="bg-white/5 border border-white/10 rounded-lg p-8 mb-12">
            <h3 className="text-2xl font-bold mb-6">Development Process</h3>
            <ol className="space-y-4">
              <li className="flex items-center">
                <span className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center mr-4">1</span>
                <span>Initial consultation to understand your requirements</span>
              </li>
              <li className="flex items-center">
                <span className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center mr-4">2</span>
                <span>Design and architecture planning</span>
              </li>
              <li className="flex items-center">
                <span className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center mr-4">3</span>
                <span>Development and testing phase</span>
              </li>
              <li className="flex items-center">
                <span className="w-8 h-8 rounded-full bg-white/10 flex items-center justify-center mr-4">4</span>
                <span>Deployment and monitoring</span>
              </li>
            </ol>
          </div>

          <div className="text-center">
            <Link
              to="/login"
              className="inline-flex items-center px-8 py-3 rounded-full bg-white text-black hover:bg-gray-200 transition-colors text-lg font-medium"
            >
              <Zap className="w-5 h-5 mr-2" />
              Get Started with Bot Development
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}