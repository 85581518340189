const API_BASE = import.meta.env.VITE_API_BASE || "/api";

async function apiFetch(url: string, options: RequestInit) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...options.headers,
  };

  const response = await fetch(`${API_BASE}${url}`, {
    ...options,
    headers,
    credentials: "include",
  });

  if (!response.ok) {
    let errorData;
    try {
      errorData = await response.json();
    } catch {
      console.log(`HTTP Error ${response.status}`);
      errorData = { error: `Oops! We're having trouble connecting to our servers right now. Please check your internet connection and try again. We apologize for the inconvenience!` };
    }
    throw new Error(errorData.error || "Request failed");
  }

  return response.json();
}

export async function createCommission(data: {
  title: string;
  description: string;
  budget: number;
  deadline: string;
  category: "bot_development" | "server_management" | "community_support";
  requirements: string;
}) {
  return apiFetch("/commission", {
    method: "POST",
    body: JSON.stringify(data),
  });
}

export async function getCommissions() {
  return apiFetch("/commissions", {
    method: "GET",
  });
}