import { Link } from 'react-router-dom';
import { Code2, MessageSquare, Headphones, ArrowRight, Zap } from 'lucide-react';

export function Services() {
  const services = [
    {
      icon: <Code2 className="w-16 h-16 mb-6" />,
      title: "Bot Development",
      description: "Custom Discord bots tailored to your server's needs with advanced features and scalability.",
      features: [
        "Custom commands and functionality",
        "Advanced moderation features",
        "Server analytics and reporting",
        "Integration with external services",
        "Automated workflows",
        "Role management systems",
        "Custom event handling",
        "24/7 bot monitoring"
      ],
      benefits: [
        "Streamline server management",
        "Enhance user experience",
        "Reduce manual workload",
        "Increase engagement"
      ],
      link: "/services/bot-development"
    },
    {
      icon: <MessageSquare className="w-16 h-16 mb-6" />,
      title: "Server Management",
      description: "Professional server setup, moderation, and ongoing maintenance services.",
      features: [
        "Channel organization and setup",
        "Role hierarchy management",
        "Automated workflows",
        "Security configuration",
        "Custom welcome systems",
        "Verification processes",
        "Backup management",
        "Regular maintenance"
      ],
      benefits: [
        "Professional organization",
        "Enhanced security",
        "Better user experience",
        "Reduced administrative burden"
      ],
      link: "/services/server-management"
    },
    {
      icon: <Headphones className="w-16 h-16 mb-6" />,
      title: "Community Support",
      description: "24/7 dedicated support team to handle user inquiries and maintain server harmony.",
      features: [
        "Round-the-clock moderation",
        "User assistance and support",
        "Event management",
        "Community engagement",
        "Conflict resolution",
        "Content moderation",
        "User feedback handling",
        "Regular community updates"
      ],
      benefits: [
        "Active community management",
        "Increased user satisfaction",
        "Safer environment",
        "Higher engagement rates"
      ],
      link: "/services/community-support"
    }
  ];

  return (
    <div className="pt-16">
      <section className="py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Our Services
          </h2>
          
          <div className="grid gap-12">
            {services.map((service, index) => (
              <div key={index} className="group">
                <div className="p-8 rounded-lg bg-white/5 border border-white/10 group-hover:border-white/20 transition-all">
                  <div className="grid md:grid-cols-2 gap-8 items-start">
                    <div>
                      {service.icon}
                      <h3 className="text-3xl font-bold mb-4">{service.title}</h3>
                      <p className="text-gray-400 text-lg mb-6">{service.description}</p>
                      
                      <h4 className="text-xl font-semibold mb-4">Key Benefits</h4>
                      <ul className="grid grid-cols-2 gap-4 mb-6">
                        {service.benefits.map((benefit, idx) => (
                          <li key={idx} className="flex items-center text-gray-300">
                            <Zap className="w-4 h-4 mr-2 text-purple-400" />
                            {benefit}
                          </li>
                        ))}
                      </ul>

                      <Link
                        to={service.link}
                        className="inline-flex items-center px-6 py-3 rounded-full bg-white text-black hover:bg-gray-200 transition-colors"
                      >
                        Learn More
                        <ArrowRight className="w-4 h-4 ml-2" />
                      </Link>
                    </div>
                    
                    <div>
                      <h4 className="text-xl font-semibold mb-4">Features</h4>
                      <ul className="grid gap-3">
                        {service.features.map((feature, idx) => (
                          <li key={idx} className="flex items-center text-gray-300 bg-white/5 p-3 rounded-lg">
                            <ArrowRight className="w-4 h-4 mr-2 text-gray-500" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-16 text-center">
            <Link
              to="/login"
              className="inline-flex items-center px-8 py-3 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 text-white hover:from-purple-600 hover:to-blue-600 transition-colors text-lg font-medium"
            >
              <Zap className="w-5 h-5 mr-2" />
              Get Started Today
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}