const API_BASE = import.meta.env.VITE_API_BASE || "/api";

async function apiFetch(url: string, options: RequestInit) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...options.headers,
  };

  const response = await fetch(`${API_BASE}${url}`, {
    ...options,
    headers,
    credentials: "include",
  });

  if (!response.ok) {
    let errorData;
    try {
      errorData = await response.json();
    } catch {
      console.log(`HTTP Error ${response.status}`);
      errorData = { error: "Oops! We're having trouble connecting to our servers right now. Please check your internet connection and try again. We apologize for the inconvenience!" };
    }
    throw new Error(errorData.error || "Request failed");
  }

  return response.json();
}

export async function getConversations() {
  return apiFetch("/conversations", {
    method: "GET",
  });
}

export async function getMessages(conversationId: string) {
  return apiFetch(`/conversations/${conversationId}/messages`, {
    method: "GET",
  });
}

export async function sendMessage(conversationId: string, content: string) {
  return apiFetch(`/conversations/${conversationId}/messages`, {
    method: "POST",
    body: JSON.stringify({ content }),
  });
}