import React from 'react';
import { Link } from 'react-router-dom';
import { MessageSquare, Code2, Headphones, Mail, Disc as Discord } from 'lucide-react';

export function Home() {
  const [contactMethod, setContactMethod] = React.useState<'form' | 'discord'>('form');
  
  const services = [
    {
      icon: <Code2 className="w-8 h-8 mb-4" />,
      title: "Bot Development",
      description: "Custom Discord bots tailored to your server's needs with advanced features and scalability.",
      link: "/services/bot-development"
    },
    {
      icon: <MessageSquare className="w-8 h-8 mb-4" />,
      title: "Server Management",
      description: "Professional server setup, moderation, and ongoing maintenance services.",
      link: "/services/server-management"
    },
    {
      icon: <Headphones className="w-8 h-8 mb-4" />,
      title: "Community Support",
      description: "24/7 dedicated support team to handle user inquiries and maintain server harmony.",
      link: "/services/community-support"
    }
  ];

  return (
    <>
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 via-transparent to-blue-500/20 animate-pulse" />
        </div>
        <div className="relative min-h-screen flex items-center">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
            <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              Take Your Online Experience to the Next Level
            </h1>
            <p className="text-xl text-gray-400 mb-8 max-w-2xl">
              Expert solutions tailored for you. From custom development to seamless management, connect, create, and elevate.
            </p>
            <Link
              to="/login"
              className="inline-block px-8 py-3 rounded-full bg-white text-black hover:bg-gray-200 transition-colors text-lg font-medium"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>

      {/* About Section */}
      <section className="py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            About ServicesX
          </h2>
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <p className="text-gray-400 text-lg mb-6">
                ServicesX connects clients with expert freelancers to bring ideas to life. Whether you need custom development, design, or full-scale management, we deliver top-tier solutions tailored to your needs.
              </p>
              <p className="text-gray-400 text-lg mb-6">
                Our team is dedicated to efficiency, quality, and innovation, ensuring every project is handled with expertise and professionalism.
              </p>
            </div>

            <div className="flex justify-center space-x-8 mt-10 items-center flex-wrap sm:flex-nowrap">
              <div className="relative group">
                <img 
                  src="./jake.jpg"
                  alt="Avatar 1" 
                  className="w-32 h-32 rounded-full border-4 border-gray-500 shadow-lg transform transition-transform hover:scale-105 object-cover max-w-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex flex-col justify-center items-center text-white transition-opacity">
                  <span className="text-xl font-semibold">Jake</span>
                  <span className="text-sm text-gray-300 mt-1">CEO</span>
                </div>
              </div>

              <div className="relative group">
                <img 
                  src="./matei.jpg"
                  alt="Avatar 2" 
                  className="w-32 h-32 rounded-full border-4 border-gray-500 shadow-lg transform transition-transform hover:scale-105 object-cover max-w-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex flex-col justify-center items-center text-white transition-opacity">
                  <span className="text-xl font-semibold">Matei</span>
                  <span className="text-sm text-gray-300 mt-1">COO</span>
                </div>
              </div>

              <div className="relative group">
                <img 
                  src="./andrew.jpg"
                  alt="Avatar 3" 
                  className="w-32 h-32 rounded-full border-4 border-gray-500 shadow-lg transform transition-transform hover:scale-105 object-cover max-w-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex flex-col justify-center items-center text-white transition-opacity">
                  <span className="text-xl font-semibold">Andrew</span>
                  <span className="text-sm text-gray-300 mt-1">CTO</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Preview Section */}
      <section className="py-20 relative bg-black/50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Our Services
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <Link
                key={index}
                to={service.link}
                className="p-6 rounded-lg bg-gradient-to-b from-white/5 to-white/10 backdrop-blur-sm border border-white/10 hover:border-white/20 transition-all"
              >
                {service.icon}
                <h3 className="text-xl font-bold mb-2">{service.title}</h3>
                <p className="text-gray-400">{service.description}</p>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black" />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
            Get in Touch
          </h2>
          <div className="max-w-2xl mx-auto">
            <div className="flex justify-center mb-8">
              <div className="relative inline-flex items-center p-1 rounded-full bg-white/10 overflow-hidden">
                <div
                  className="absolute top-0 left-0 w-1/2 h-full bg-white rounded-full transition-transform duration-500 ease-in-out"
                  style={{
                    transform: contactMethod === 'form' ? 'translateX(0%)' : 'translateX(100%)'
                  }}
                />
                <button
                  onClick={() => setContactMethod('form')}
                  className={`relative z-10 px-6 py-2 rounded-full transition-colors duration-500 ease-in-out ${
                    contactMethod === 'form' ? 'text-black' : 'text-white hover:text-gray-200'
                  }`}
                >
                  <Mail className="w-5 h-5 inline-block mr-2" />
                  Contact Form
                </button>
                <button
                  onClick={() => setContactMethod('discord')}
                  className={`relative z-10 px-6 py-2 rounded-full transition-colors duration-500 ease-in-out ${
                    contactMethod === 'discord' ? 'text-black' : 'text-white hover:text-gray-200'
                  }`}
                >
                  <Discord className="w-5 h-5 inline-block mr-2" />
                  Join Discord
                </button>
              </div>
            </div>

            <div className="relative overflow-hidden">
              <div
                className="flex transition-transform duration-500 ease-in-out"
                style={{ transform: `translateX(${contactMethod === 'form' ? '0%' : '-100%'})` }}
              >
                <div className="w-full flex-shrink-0 px-4">
                  <form className="space-y-6">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-400 mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/10 focus:border-white/20 focus:outline-none focus:ring-2 focus:ring-white/10 text-white"
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-400 mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/10 focus:border-white/20 focus:outline-none focus:ring-2 focus:ring-white/10 text-white"
                      />
                    </div>
                    <div>
                      <label htmlFor="service" className="block text-sm font-medium text-gray-400 mb-2">
                        Service Interested In
                      </label>
                      <select
                        id="service"
                        className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/10 focus:border-white/20 focus:outline-none focus:ring-2 focus:ring-white/10 text-white"
                      >
                        <option value="">Select a service</option>
                        <option value="bot">Bot Development</option>
                        <option value="management">Server Management</option>
                        <option value="support">Community Support</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="message" className="block text-sm font-medium text-gray-400 mb-2">
                        Message
                      </label>
                      <textarea
                        id="message"
                        rows={4}
                        className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/10 focus:border-white/20 focus:outline-none focus:ring-2 focus:ring-white/10 text-white"
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="w-full px-8 py-3 rounded-full bg-white text-black hover:bg-gray-200 transition-colors text-lg font-medium"
                    >
                      Send Message
                    </button>
                  </form>
                </div>

                <div className="w-full flex-shrink-0 px-4">
                  <div className="text-center">
                    <a
                      href="https://discord.gg/Jwk82qE9yK"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-8 py-3 rounded-full bg-[#5865F2] text-white hover:bg-[#4752C4] transition-colors text-lg font-medium"
                    >
                      <Discord className="w-6 h-6 mr-2" />
                      Join Our Discord Server
                    </a>
                    <p className="mt-4 text-gray-400">
                      Join our Discord server to discuss your needs and get started with our services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}