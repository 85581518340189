import React, { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Menu, X, Github, Mail } from 'lucide-react';
import { useAuthStore } from '../store/useAuthStore';
import { getCurrentUser } from '../api/auth';

export function Layout() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { user, setUser, checkingSession, setCheckingSession } = useAuthStore();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const res = await getCurrentUser();
        if (res.user) {
          setUser(res.user);
        }
      } catch (error) {
        // Not authenticated; no need to do anything
      } finally {
        setCheckingSession(false);
      }
    };

    checkSession();
  }, [setUser, setCheckingSession]);

  if (checkingSession) {
    return (
      <div className="fixed inset-0 bg-black flex items-center justify-center">
        <div className="relative">
          {/* Main glowing orb */}
          <div className="w-16 h-16 rounded-full bg-gradient-to-br from-purple-500 to-blue-500 animate-pulse">
            <div className="absolute inset-0 rounded-full bg-gradient-to-br from-purple-500 to-blue-500 blur-xl opacity-50 animate-pulse"></div>
          </div>
          
          {/* Orbiting particles */}
          <div className="absolute inset-0 animate-spin" style={{ animationDuration: '3s' }}>
            <div className="absolute -top-2 left-1/2 w-2 h-2 rounded-full bg-white transform -translate-x-1/2"></div>
          </div>
          <div className="absolute inset-0 animate-spin" style={{ animationDuration: '4s' }}>
            <div className="absolute top-1/2 -right-2 w-2 h-2 rounded-full bg-purple-400 transform -translate-y-1/2"></div>
          </div>
          <div className="absolute inset-0 animate-spin" style={{ animationDuration: '5s' }}>
            <div className="absolute -bottom-2 left-1/2 w-2 h-2 rounded-full bg-blue-400 transform -translate-x-1/2"></div>
          </div>
          
          {/* Pulse rings */}
          <div className="absolute inset-0 animate-ping">
            <div className="absolute inset-0 rounded-full border-2 border-white/20"></div>
          </div>
          <div className="absolute inset-0 animate-ping" style={{ animationDelay: '500ms' }}>
            <div className="absolute inset-0 rounded-full border-2 border-white/10"></div>
          </div>
          
          {/* Loading text */}
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-8">
            <p className="text-white/70 animate-pulse">Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-black/80 backdrop-blur-sm border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Link
                to="/"
                className="text-xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent"
              >
                ServicesX
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:block">
              <div className="flex items-center space-x-8">
                <Link to="/about" className="hover:text-gray-300 transition-colors">
                  About
                </Link>
                <Link to="/services" className="hover:text-gray-300 transition-colors">
                  Services
                </Link>
                {user ? (
                  <Link
                    to="/dashboard"
                    className="px-4 py-2 rounded-full bg-white text-black hover:bg-gray-200 transition-colors"
                  >
                    Dashboard
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    className="px-4 py-2 rounded-full bg-white text-black hover:bg-gray-200 transition-colors"
                  >
                    Get Started
                  </Link>
                )}
              </div>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 rounded-md hover:bg-gray-800"
              >
                {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <Link
                to="/about"
                className="block px-3 py-2 hover:bg-gray-800 rounded-md"
              >
                About
              </Link>
              <Link
                to="/services"
                className="block px-3 py-2 hover:bg-gray-800 rounded-md"
              >
                Services
              </Link>
              {user ? (
                <Link
                  to="/dashboard"
                  className="block px-3 py-2 hover:bg-gray-800 rounded-md"
                >
                  Dashboard
                </Link>
              ) : (
                <Link
                  to="/login"
                  className="block px-3 py-2 hover:bg-gray-800 rounded-md"
                >
                  Get Started
                </Link>
              )}
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <main>
        <Outlet />
      </main>

      {/* Footer */}
      <footer className="bg-black/80 border-t border-white/10 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4 bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
                ServicesX
              </h3>
              <p className="text-gray-400">
                Elevating online communities with professional services and solutions.
              </p>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
              <ul className="space-y-2 text-gray-400">
                <li>
                  <Link to="/about" className="hover:text-white transition-colors">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="hover:text-white transition-colors">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/login" className="hover:text-white transition-colors">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Services</h4>
              <ul className="space-y-2 text-gray-400">
                <li>
                  <Link
                    to="/services/bot-development"
                    className="hover:text-white transition-colors"
                  >
                    Bot Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/server-management"
                    className="hover:text-white transition-colors"
                  >
                    Server Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/community-support"
                    className="hover:text-white transition-colors"
                  >
                    Community Support
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Connect</h4>
              <div className="flex space-x-4">
                <a
                  href="https://github.com/ServicesX"
                  target="_blank"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <Github className="w-6 h-6" />
                </a>
                <a
                  href="https://discord.gg/Jwk82qE9yK"
                  target="_blank"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20.317 4.369a19.791 19.791 0 00-4.885-1.515.07.07 0 00-.073.035c-.21.375-.444.864-.608 1.249-1.844-.276-3.68-.276-5.486 0-.164-.39-.418-.874-.63-1.249a.078.078 0 00-.073-.035c-1.69.285-3.33.785-4.884 1.515a.07.07 0 00-.032.028C.533 9.045-.32 13.579.099 18.057a.082.082 0 00.031.056c2.052 1.504 4.045 2.422 5.992 3.029a.07.07 0 00.076-.028c.46-.63.873-1.295 1.226-1.994a.07.07 0 00-.041-.097c-.652-.247-1.27-.547-1.872-.892a.07.07 0 01-.007-.12c.125-.094.25-.192.37-.291a.07.07 0 01.073-.01c3.927 1.793 8.18 1.793 12.061 0a.07.07 0 01.074.009c.12.099.245.198.37.292a.07.07 0 01-.006.12 12.509 12.509 0 01-1.873.891.07.07 0 00-.04.098c.36.698.773 1.362 1.225 1.993a.07.07 0 00.076.028c1.957-.607 3.95-1.525 6.002-3.029a.07.07 0 00.031-.056c.5-5.177-.838-9.673-3.549-13.66a.061.061 0 00-.031-.028zM8.02 15.331c-1.183 0-2.156-1.085-2.156-2.419 0-1.333.955-2.418 2.156-2.418 1.21 0 2.175 1.085 2.156 2.418 0 1.334-.955 2.419-2.156 2.419zm7.975 0c-1.183 0-2.156-1.085-2.156-2.419 0-1.333.955-2.418 2.156-2.418 1.21 0 2.175 1.085 2.156 2.418 0 1.334-.946 2.419-2.156 2.419z"/>
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Mail className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-white/10 text-center text-gray-400">
            <p>&copy; {new Date().getFullYear()} ServicesX. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}