import { ScrollToTop } from './components/ScrollToTop';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Services } from './pages/Services';
import { Login } from './pages/Login';
import { BotDevelopment } from './pages/services/BotDevelopment';
import { ServerManagement } from './pages/services/ServerManagement';
import { CommunitySupport } from './pages/services/CommunitySupport';
import { DashboardPage } from './pages/dashboard/Dashboard';
import { CreateCommissionPage } from './pages/commission/CreateCommissionPage';
import { MessagesPage } from './pages/messages/MessagesPage';

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Login />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="commission/new" element={<CreateCommissionPage />} />
          <Route path="messages" element={<MessagesPage />} />
          <Route path="services/bot-development" element={<BotDevelopment />} />
          <Route path="services/server-management" element={<ServerManagement />} />
          <Route path="services/community-support" element={<CommunitySupport />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;