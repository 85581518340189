const API_BASE = import.meta.env.VITE_API_BASE || "/api";

async function authFetch(url: string, options: RequestInit) {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    ...options.headers,
  };

  let response;
  try {
    response = await fetch(`${API_BASE}${url}`, {
      ...options,
      headers,
      credentials: 'include',
    });
  } catch (networkError) {
    throw new Error("Oops! We're having trouble connecting to our servers right now. Please check your internet connection and try again.");
  }

  if (!response.ok) {
    let errorData;
    try {
      errorData = await response.json();
    } catch {
      console.log(`HTTP Error ${response.status}`);
      errorData = { error: "Oops! Something went wrong. Please try again later." };
    }
    throw new Error(errorData.error || 'Request failed');
  }

  return response.json();
}

export async function loginUser(email: string, password: string) {
  return authFetch('/auth/login', {
    method: 'POST',
    body: JSON.stringify({ username: email, password }),
  });
}

export async function registerUser(name: string, email: string, password: string) {
  return authFetch('/auth/register', {
    method: 'POST',
    body: JSON.stringify({ 
      username: email, 
      password, 
      name 
    }),
  });
}

export async function getCurrentUser() {
  try {
    return await authFetch('/auth/me', {
      method: 'GET',
    });
  } catch (error) {
    if (error instanceof Error && error.message.includes('401')) {
      return { user: null };
    }
    throw error;
  }
}

export async function logoutUser() {
  return authFetch('/auth/logout', {
    method: 'POST',
  });
}

export async function checkAuth() {
  try {
    const { user } = await getCurrentUser();
    return !!user;
  } catch {
    return false;
  }
}